'use client';

import { useLogout } from '@/hooks/useLogout';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';

declare global {
  interface Window {
    queryClient?: QueryClient;
  }
}
export default function Provider({ children }: { children: React.ReactNode }) {
  const { clearUserData } = useLogout();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry(failureCount, error) {
              if ('status' in error && error?.status === 401) {
                return false;
              }
              return failureCount < 3;
            },
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            if ('status' in error && error?.status === 401) {
              clear();
              clearUserData();
            }
          },
        }),
      })
  );
  if (typeof window !== 'undefined' && !window.queryClient) {
    window.queryClient = queryClient;
  }

  function clear() {
    queryClient.cancelQueries();
  }

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools position='bottom' initialIsOpen={false} />
    </QueryClientProvider>
  );
}
