'use client';

import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { type ThemeProviderProps } from 'next-themes/dist/types';
export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  /* This is a workaround for Warning: Extra attributes from the server: class, style at html in the browser console.
  The <html suppressHydrationWarning={true} lang='en'> does not work for some reason.
  More info here: https://github.com/pacocoursey/next-themes#with-app & https://github.com/vercel/next.js/discussions/22388#discussioncomment-6609801 */

  // const [isClient, setIsClient] = useState(false);

  // useEffect(() => {
  //   setIsClient(true);
  // }, []);

  return (
    // <>
    //   {isClient ? (
    <NextThemesProvider
      defaultTheme='light'
      themes={['system', 'light', 'dark']}
      {...props}
    >
      {children}
    </NextThemesProvider>
    //   ) : (
    //     <></>
    //   )}
    // </>
  );
}
