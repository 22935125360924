import { clearCookies } from '@/lib/clear-cookies';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useWindowKeeper } from './useWindowKeeper';

export function useLogout() {
  const router = useRouter();
  const { resetPreviews } = useWindowKeeper();

  const clearUserData = useCallback(
    ({ withReturnUrl = true } = {}) => {
      clearCookies();
      resetPreviews();
      datadogRum.clearUser();
      if (withReturnUrl) {
        router.replace(
          `/login/credentials?nextUrl=${window.location.pathname}`
        );
      } else {
        router.replace('/login/credentials');
      }
    },
    [resetPreviews, router]
  );

  return { clearUserData };
}
